import React, {createRef} from 'react';
import {Container, Grid} from 'semantic-ui-react';
import 'fomantic-ui-less/semantic.less';
import HeaderTopContent from './HeaderTopContent';
import LayoutHeader from './LayoutHeader';
import LayoutFooter from './LayoutFooter';
import Sidebar from './Sidebar';

const pageRef = createRef();
const Page = ({children, hero, sidebar}) => {
  const hasSidebar = sidebar !== false;
  return (
    <div className="site" ref={pageRef}>
      <HeaderTopContent hero={hero} />
      <LayoutHeader pageRef={pageRef} />
      <Container>
        <Grid centered>
          <Grid.Column
            mobile={16}
            tablet={hasSidebar ? 10 : 16}
            computer={hasSidebar ? 11 : 14}>
            {children}
          </Grid.Column>
          {hasSidebar && (
            <Grid.Column mobile={16} tablet={6} computer={5}>
              <Sidebar type={sidebar} />
            </Grid.Column>
          )}
        </Grid>
      </Container>
      <LayoutFooter />
    </div>
  );
};

export default Page;
