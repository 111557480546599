/**
 * SEO component that queries for data with Gatsby's useStaticQuery React hook:
 * https://www.gatsbyjs.org/docs/use-static-query/
 *
 * Based on tutorial:
 * https://www.gatsbyjs.org/tutorial/seo-and-social-sharing-cards-tutorial/
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import urlJoin from 'url-join';
import useSiteSettings from '../../../hooks/use-site-settings';
import SchemaOrg from './SchemaOrg';

const SEO = ({
  description,
  lang,
  meta,
  keywords,
  image: metaImage,
  title,
  pathname,
  contentType,
  children,
}) => {
  const siteSettings = useSiteSettings();

  const metaDescription = description || siteSettings.description;
  const metaKeywords = (keywords || siteSettings.keywords).join(',');
  const image = metaImage
    ? {
        alt: title,
        ...metaImage,
      }
    : siteSettings.seoImage;
  const fullImageUrl = image.src
    ? urlJoin(siteSettings.siteUrl, image.src)
    : null;
  const canonical = pathname
    ? urlJoin(siteSettings.siteUrl, pathname)
    : siteSettings.siteUrl;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${siteSettings.seoTitle}`}
        link={canonical ? [{rel: 'canonical', href: canonical}] : []}
        meta={[
          {name: 'description', content: metaDescription},
          {name: 'keywords', content: metaKeywords},
          {property: 'og:title', content: title},
          {property: 'og:description', content: metaDescription},
          {property: 'og:type', content: contentType},
          // { name: 'twitter:creator', content: `@${twitterId}` },
          {name: 'twitter:title', content: title},
          {name: 'twitter:description', content: metaDescription},
        ]
          .concat([
            {property: 'og:image', content: fullImageUrl},
            {property: 'og:image:width', content: image.width},
            {property: 'og:image:height', content: image.height},
            {property: 'og:image:secure_url', content: fullImageUrl},
            {property: 'og:image:alt', content: image.alt},
            {name: 'twitter:card', content: 'summary_large_image'},
            {
              property: 'twitter:image:secure_url',
              content: image.alt,
            },
            {property: 'twitter:image:alt', content: image.alt},
          ])
          .concat(meta)}
      />

      {children || <SchemaOrg title={title} pathname={pathname} />}
    </>
  );
};

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  keywords: undefined,
  image: undefined,
  pathname: undefined,
  contentType: 'website',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    alt: PropTypes.string,
  }),
  pathname: PropTypes.string,
  contentType: PropTypes.oneOf(['website', 'article']),
};

export default SEO;
