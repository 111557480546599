// Adapted from: https://github.com/jlengstorf/gatsby-theme-jason-blog/blob/master/src/components/SEO/SchemaOrg.js
import React from 'react';
import {Helmet} from 'react-helmet';
import urlJoin from 'url-join';
import useSiteSettings from '../../../hooks/use-site-settings';

const SchemaOrg = ({title, pathname}) => {
  const {siteUrl, seoTitle} = useSiteSettings();
  const url = pathname ? urlJoin(siteUrl, pathname) : siteUrl;
  const schema = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url,
      name: title,
      alternateName: seoTitle,
    },
  ];

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

export default SchemaOrg;
