import React, {useState} from 'react';
import styled from 'styled-components';
import {Container, Icon, Menu, Sticky} from 'semantic-ui-react';
import {Link} from 'gatsby';
import {colors} from '../../../theme';
import {Media} from '../../../media';

const MENU_SPEC = [
  {label: 'Home', to: '/'},
  {label: 'Blog', to: '/articles'},
  {label: 'About', to: '/about'},
  {label: 'Contact', to: '/contact'},
];

const HeaderMainContainer = styled.div`
  .ui.sticky {
    background: #fff;
  }

  .ui.container {
    border-bottom: 5px solid ${colors.brandHighlightDark};
  }

  .ui.text.menu {
    width: 100%;
    max-height: 300px;
    overflow: auto;

    &.flat-menu {
      justify-content: center;
    }

    &.slide-down-menu {
      margin: 0.5rem 0.5rem 0.25rem;
    }

    &.slide-down-menu__content {
      margin: 0;
    }

    .item {
      font-size: 0.85rem;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.5rem;
      padding: 10px 12px;
      color: rgba(0, 0, 0, 0.8);

      &.active,
      &:hover {
        font-weight: 700;
        color: ${colors.brandPrimary};
      }
    }
  }
`;

const MenuLink = ({children, ...props}) => (
  <Menu.Item as={Link} activeClassName="active" {...props}>
    {children}
  </Menu.Item>
);

const FlatMenu = ({pageRef}) => (
  <>
    <HeaderMainContainer className="huge-bm">
      <Sticky context={pageRef}>
        <Container textAlign="center">
          <Menu className="flat-menu " as="nav" text borderless>
            {MENU_SPEC.map(({label, to}) => (
              <MenuLink key={`${label} -> ${to}`} to={to}>
                {label}
              </MenuLink>
            ))}
          </Menu>
        </Container>
      </Sticky>
    </HeaderMainContainer>
  </>
);

const SlideDownMenu = ({pageRef}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => setMenuVisible(!menuVisible);

  return (
    <>
      <HeaderMainContainer className="huge-bm">
        <Sticky context={pageRef}>
          <Container>
            <Menu className="slide-down-menu" text borderless>
              <Menu.Item onClick={toggleMenu} position="right" active>
                <Icon name={menuVisible ? 'close' : 'sidebar'} size="large" />
              </Menu.Item>
            </Menu>
            {menuVisible && (
              <Menu
                className="slide-down-menu__content thin-scroll"
                vertical
                text
                borderless>
                {menuVisible &&
                  MENU_SPEC.map(({label, to}) => (
                    <MenuLink key={`${label} -> ${to}`} to={to}>
                      {label}
                    </MenuLink>
                  ))}
              </Menu>
            )}
          </Container>
        </Sticky>
      </HeaderMainContainer>
    </>
  );
};

const LayoutHeader = ({pageRef}) => (
  <>
    <Media at="mobile">
      <SlideDownMenu pageRef={pageRef} />
    </Media>
    <Media greaterThan="mobile">
      <FlatMenu pageRef={pageRef} />
    </Media>
  </>
);

export default LayoutHeader;
