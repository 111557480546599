import React from 'react';
import styled from 'styled-components';
import {Header} from 'semantic-ui-react';
import {colorsRaw} from '../../theme';

const StyledHeader = styled(Header)`
  padding-top: 20px;
  margin-bottom: 40px;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${colorsRaw.lightBlack};

  span {
    display: inline-block;
    position: relative;

    &:first-letter {
      margin-right: 7px;
      padding: 5px 12px;
      font-size: 16px;
      letter-spacing: 0;
      color: ${colorsRaw.white};
      background-color: ${colorsRaw.lightBlack};
    }
  }
`;

const PageWidgetTitle = ({children}) => (
  <StyledHeader as="h2" className="huge-bm">
    <span>{children}</span>
  </StyledHeader>
);

export default PageWidgetTitle;
