import {useCallback, useState} from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import {trackSubscribeToNewsletter} from '../tracking';

const useSubscribe = ({campaign}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [subscribing, setSubscribing] = useState(false);
  const performSubscribe = useCallback(() => {
    if (!(name && name.length)) {
      setError('Please enter your name');
      return;
    }
    if (!(email && email.length)) {
      setError('Please enter your email address');
      return;
    }

    const names = name.split(' ');
    const fields = {email};
    if (names.length > 1) {
      fields.LNAME = names[names.length - 1];
      fields.FNAME = names.slice(0, names.length - 1).join(' ');
    } else {
      // eslint-disable-next-line prefer-destructuring
      fields.FNAME = names[0];
    }

    setError(null);
    setSuccess(null);
    setSubscribing(true);
    addToMailchimp(email, fields)
      .then(data => {
        if (data.result === 'error') {
          trackSubscribeToNewsletter({campaign, error: data.msg});
          setSuccess(null);
          setError(data.msg);
        } else {
          trackSubscribeToNewsletter({campaign});
          setError(null);
          setSuccess(
            `Thanks for registering, ${name}. Expect fresh content in your inbox every month!`,
          );
        }
      })
      .finally(() => {
        setSubscribing(false);
      });
  }, [campaign, email, name]);

  const setSanitizedName = val => setName(val?.trim());
  const setSanitizedEmail = val => setEmail(val?.trim());

  return {
    name,
    setName: setSanitizedName,
    email,
    setEmail: setSanitizedEmail,
    error,
    success,
    subscribing,
    performSubscribe,
  };
};

export default useSubscribe;
