import React from 'react';
import styled from 'styled-components';
import {Icon} from 'semantic-ui-react';
import {OutboundLink} from 'gatsby-plugin-google-analytics';
import PageWidgetContainer from '../PageWidgetContainer';
import {colors} from '../../../theme';
import useSiteSettings from '../../../hooks/use-site-settings';

const ICON_SIZE_REM = 2.25;

const SocialIconsList = styled.ul`
  display: block;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const socialColors = {
  facebook: [colors.facebook, colors.facebookActive],
  twitter: [colors.twitter, colors.twitterActive],
  instagram: [colors.instagram, colors.instagramActive],
  linkedin: [colors.linkedIn, colors.linkedInActive],
  youtube: [colors.youTube, colors.youTubeActive],
};

const linkStyle = (type, defaultColor, activeColor) => `&.${type}-link {
  color: ${defaultColor};
  &:hover {
    color: ${activeColor};
  }
}`;

const SocialIconsListItem = styled.li`
  display: inline-block;

  a {
    display: block;
    font-size: ${ICON_SIZE_REM}rem;
    line-height: ${ICON_SIZE_REM}rem;
    margin: 0 0.5rem;
    text-align: center;
    color: #858899;
    transition: all 0.1s linear;

    i {
      margin: 0;
    }

    ${Object.entries(socialColors).map(([type, [defaultColor, activeColor]]) =>
      linkStyle(type, defaultColor, activeColor),
    )}
  }
`;

const SocialIcon = ({name, href, ...props}) => (
  <OutboundLink
    href={href}
    target="_blank"
    className={`${name}-link`}
    rel="nofollow noopener noreferrer">
    <Icon name={name} aria-label={`Visit my ${name} page`} {...props} />
  </OutboundLink>
);

const SocialPageWidget = () => {
  const siteSettings = useSiteSettings();
  return (
    <PageWidgetContainer title="Keep In Touch">
      <SocialIconsList className="text-centered">
        <SocialIconsListItem>
          <SocialIcon name="facebook" href={siteSettings.facebookLink} />
        </SocialIconsListItem>
        <SocialIconsListItem>
          <SocialIcon name="instagram" href={siteSettings.instagramLink} />
        </SocialIconsListItem>
        <SocialIconsListItem>
          <SocialIcon name="youtube" href={siteSettings.youtubeLink} />
        </SocialIconsListItem>
        <SocialIconsListItem>
          <SocialIcon name="linkedin" href={siteSettings.linkedInLink} />
        </SocialIconsListItem>
      </SocialIconsList>
    </PageWidgetContainer>
  );
};

export default SocialPageWidget;
