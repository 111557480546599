import React from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql, Link} from 'gatsby';
import PageWidgetContainer from '../PageWidgetContainer';

const useData = () => {
  const result = useStaticQuery(
    graphql`
      query articleTagsPageWidget {
        ahp {
          findArticleTags {
            nodes {
              slug
              tag
              articleCount
            }
          }
        }
      }
    `,
  );

  return result?.ahp?.findArticleTags?.nodes;
};

const TagCloud = styled.div`
  text-align: center;
`;

const TagLink = styled(Link)`
  display: inline-block;
  padding: 5px 12px;
  margin-right: 5px;
  margin-bottom: 8px;
  border: 1px solid #6b6e80;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 12px !important;
  line-height: 22px !important;
  text-transform: capitalize;
  color: #6b6e80;
  background-color: #f2f2f2;

  &:hover {
    color: #2b2b2b;
  }
`;

const TagLinkSpan = styled.span`
  display: inline-block;
  position: relative;
  line-height: 14px;
  padding: 3px 0 3px 10px;
  margin-left: 6px;
  border-left: 1px solid #6b6e80;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 6px;
    border-top: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;
  }

  &::before {
    left: 0;
    border-left: 4px solid #6b6e80;
  }

  &::after {
    left: -1px;
    border-left: 4px solid #f2f2f2;
  }
`;

const ArticleTagsPageWidget = () => {
  const tags = useData();
  if (!(Array.isArray(tags) && tags.length)) return null;
  return (
    <PageWidgetContainer title="Tags">
      <TagCloud>
        {tags.map(({slug, tag, articleCount}) => (
          <TagLink key={slug} to={`/article-tags/${slug}`}>
            {tag} <TagLinkSpan>{articleCount}</TagLinkSpan>
          </TagLink>
        ))}
      </TagCloud>
    </PageWidgetContainer>
  );
};

export default ArticleTagsPageWidget;
