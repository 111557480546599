import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import {Grid, Icon, Menu} from 'semantic-ui-react';
import {OutboundLink} from 'gatsby-plugin-google-analytics';
import useSiteSettings from '../../../hooks/use-site-settings';

const StyledGrid = styled(Grid)`
  justify-content: center;
`;

const SocialMenuLink = ({children, ...props}) => {
  const {iconName, ...otherProps} = props;
  return (
    <Menu.Item
      as={OutboundLink}
      className="icon-link"
      {...otherProps}
      target="_blank"
      rel="nofollow noopener noreferrer">
      <Icon
        name={iconName}
        aria-label={children || `Visit my ${iconName} page`}
      />
      {children}
    </Menu.Item>
  );
};

export default ({centered, inverted}) => {
  const siteSettings = useSiteSettings();
  return (
    <Menu className={classNames({'social-menu': true, inverted})} text>
      <StyledGrid container centered={centered}>
        <SocialMenuLink iconName="facebook" href={siteSettings.facebookLink} />
        <SocialMenuLink
          iconName="instagram"
          href={siteSettings.instagramLink}
        />
        <SocialMenuLink iconName="youtube" href={siteSettings.youtubeLink} />
        <SocialMenuLink iconName="linkedin" href={siteSettings.linkedInLink} />
        <Menu.Item as="a" href={siteSettings.phoneLink}>
          {siteSettings.phone}
        </Menu.Item>
      </StyledGrid>
    </Menu>
  );
};
