import {css} from 'styled-components';

/*
 * Breakpoints
 */
const PX_TABLET = 768;
const PX_SMALL_SCREEN = 992;
const PX_LARGE_SCREEN = 1200;
export const breakpoints = Object.freeze({
  mobileMax: `${PX_TABLET - 1}px`,
  tablet: `${PX_TABLET}px`,
  tabletMax: `${PX_SMALL_SCREEN - 1}px`,
  smallScreen: `${PX_SMALL_SCREEN}px`,
  smallScreenMax: `${PX_LARGE_SCREEN - 1}px`,
  largeScreen: `${PX_LARGE_SCREEN}px`,
});

/*
 * Theme Colors
 * See Formantic UI colors used in global theme:
 * https://github.com/fomantic/Fomantic-UI/blob/master/src/themes/default/globals/site.variables#L230
 * With our override theme colors
 */
export const colorsRaw = {
  /* ---  Colors  ---*/
  red: '#C0392B',
  watermelon: '#F62459',
  orange: '#D35400',
  yellow: '#FFA800',
  olive: '#B5CC18',
  blue: '#394C81',
  skyblue: '#2980B9',
  navyblue: '#2C3E50',
  green: '#27AE60',
  mint: '#16A085',
  teal: '#356272',
  pink: '#D45C9E',
  purple: '#5B48A2',
  violet: '#6435C9',
  magenta: '#8E44AD',
  black: '#262626',
  brown: '#8E725E',
  grey: '#6B6E80',

  /* ---  Light Colors  ---*/
  lightRed: '#E74C3C',
  lightWatermelon: '#EF717A',
  lightOrange: '#FC6E51',
  lightYellow: '#FFCD02',
  lightOlive: '#D9E778',
  lightBlue: '#5065A1',
  lightSkyblue: '#5BC0DE',
  lightNavyblue: '#34495E',
  lightGreen: '#82B440',
  lightMint: '#1ABC9C',
  lightTeal: '#3A6F81',
  lightPink: '#F47CC3',
  lightPurple: '#745EC5',
  lightViolet: '#A291FB',
  lightMagenta: '#9B59B6',
  lightBlack: '#2B2B2B',
  lightBrown: '#A38671',
  lightGrey: '#F9F9F9',

  /* ---   Neutrals  ---*/
  fullBlack: '#000000',
  offWhite: '#F9FAFB',
  darkWhite: '#F3F4F5',
  midWhite: '#D9DAE9',
  white: '#FFFFFF',

  /* --- Colored Backgrounds ---*/
  primaryBackground: '#DFF0FF',
  secondaryBackground: '#F4F4F4',
  redBackground: '#FFE8E6',
  orangeBackground: '#FFEDDE',
  yellowBackground: '#FFF8DB',
  oliveBackground: '#FBFDEF',
  greenBackground: '#E5F9E7',
  tealBackground: '#E1F7F7',
  blueBackground: '#DFF0FF',
  violetBackground: '#EAE7FF',
  purpleBackground: '#F6E7FF',
  pinkBackground: '#FFE3FB',
  brownBackground: '#F1E2D3',
  greyBackground: '#F4F4F4',
  blackBackground: '#F4F4F4',
};

export const colors = Object.freeze({
  active: colorsRaw.skyblue,
  activeBackground: colorsRaw.lightBlue,
  background: colorsRaw.white,
  brandPrimary: '#AC3B61',
  brandSecondary: '#123C69',
  brandHighlightDark: '#EDC7B7',
  brandHighlight: '#EEE2DC',
  brandMuted: '#BAB2B5',
  texasColor: '#bf5700',
  texasLightColor: '#ffa85f',
  darkerTextColor: 'rgba(0, 0, 0, 0.87)',
  darkTextColor: 'rgba(0, 0, 0, 0.85)',
  mutedTextColor: 'rgba(0, 0, 0, 0.6)',
  lightTextColor: 'rgba(0, 0, 0, 0.4)',
  textFieldBorder: '#dfdfe3',
  controlLabel: '#7a8291',
  errorText: colorsRaw.red,
  shadow: '#d4d4d5',
  checkerboardLight: colorsRaw.greyBackground,
  checkerboardDark: colorsRaw.lightGrey,

  // https://www.lockedownseo.com/social-media-colors/
  facebook: '#1877f2',
  facebookActive: '#3b5998',
  twitter: '#1da1f2',
  twitterActive: '#14171a',
  instagram: '#c32aa3',
  instagramActive: '#4c5fd7',
  linkedIn: '#007bb5',
  linkedInActive: '#000000',
  youTube: '#ff0000',
  youTubeActive: '#282828',
});

export const transitions = Object.freeze({
  main: '.2s ease',
});

/*
 * Styles
 */
const shadows = {
  drop: `
    box-shadow: 0 2px 4px 0 rgba(19, 39, 48, 0.12);
  `,
  dropMain: `
    box-shadow: 0 2px 6px 0 rgba(68, 74, 87, 0.05), 0 1px 3px 0 rgba(68, 74, 87, 0.1);
  `,
  dropMiddle: `
    box-shadow: 0 2px 6px 0 rgba(68, 74, 87, 0.15), 0 1px 3px 0 rgba(68, 74, 87, 0.3);
  `,
  dropDeep: `
    box-shadow: 0 4px 12px 0 rgba(68, 74, 87, 0.15), 0 1px 3px 0 rgba(68, 74, 87, 0.25);
  `,
  inset: `
    box-shadow: inset 0 0 4px rgba(68, 74, 87, 0.3);
  `,
};

const gradients = Object.freeze({
  checkerboard: `
    linear-gradient(
      45deg,
      ${colors.checkerboardDark} 25%,
      transparent 25%,
      transparent 75%,
      ${colors.checkerboardDark} 75%,
      ${colors.checkerboardDark}
    )
  `,
});

const effects = Object.freeze({
  checkerboard: css`
    background-color: ${colors.checkerboardLight};
    background-size: 16px 16px;
    background-position: 0 0, 8px 8px;
    background-image: ${gradients.checkerboard}, ${gradients.checkerboard};
  `,
});

export const styles = Object.freeze({
  gradients,
  effects,
  shadows,
});
