import {graphql, useStaticQuery} from 'gatsby';
import SiteSettings from '../siteSettings';

const useSiteSettings = () => {
  const data = useStaticQuery(graphql`
    query {
      ...SiteSettingsQueryFragment
      ...SiteMetadataQueryFragment
      ...SEOImageQueryFragment
      ...SEOLogoQueryFragment
    }
  `);

  const gatsbySiteMetadata = data?.site?.siteMetadata;
  const configuredSettings = data?.ahp?.siteSettings?.value;
  const seoImage = data?.seoImage?.childImageSharp?.resize;
  const seoLogo = data?.seoImage?.childImageSharp?.resize;
  return new SiteSettings({
    ...gatsbySiteMetadata,
    ...configuredSettings,
    seoImage,
    seoLogo,
  });
};

export default useSiteSettings;
