import React from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql, Link} from 'gatsby';
import PageWidgetContainer from '../PageWidgetContainer';

const useData = () => {
  const result = useStaticQuery(
    graphql`
      query articleCategoriesPageWidget {
        ahp {
          findArticleCategories {
            nodes {
              slug
              title
              articleCount
            }
          }
        }
      }
    `,
  );

  const categories = result?.ahp?.findArticleCategories?.nodes;
  if (!categories) {
    return null;
  }

  return categories
    .filter(c => c.articleCount > 0)
    .sort((a, b) => {
      if (a.slug === 'buy') return -1;
      if (b.slug === 'buy') return 1;
      if (a.slug === 'sell') return -1;
      if (b.slug === 'sell') return 1;
      if (a.slug === 'rent') return -1;
      if (b.slug === 'rent') return 1;
      return a.slug > b.slug ? 1 : 0;
    });
};

const CategoriesList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const CategoryListItem = styled.li`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    font-family: Oswald, sans-serif;
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-bottom: 1px solid #e6e6e6;
    color: #2b2b2b;

    &:hover {
      color: #6b6e80;
    }
  }
`;

const ArticleCategoriesPageWidget = () => {
  const categories = useData();
  if (!(Array.isArray(categories) && categories.length)) return null;
  return (
    <PageWidgetContainer title="Categories">
      <CategoriesList>
        {categories.map(({slug, title}) => (
          <CategoryListItem key={slug}>
            <Link to={`/article-categories/${slug}`}>{title}</Link>
          </CategoryListItem>
        ))}
      </CategoriesList>
    </PageWidgetContainer>
  );
};

export default ArticleCategoriesPageWidget;
