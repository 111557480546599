import React from 'react';
import styled from 'styled-components';
import {Form, Message} from 'semantic-ui-react';
import {breakpoints} from '../../theme';
import useSubscribe from '../../hooks/use-subscribe';

const Root = styled.div`
  background-color: #f2f2f2;

  .heading {
    font-size: 48px;
    margin-bottom: 40px;
    font-weight: normal;
  }

  .subheading {
    color: #6b6e80;
    font-size: 13px;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
  }

  .fields {
    justify-content: center;
    .field {
      padding: 0 !important;
      margin: 0.5rem 2px !important;
    }

    input[type='text'],
    input[type='email'] {
      // display: inline-block;
      height: auto;
      width: 280px !important;
      letter-spacing: 1px;
      font-size: 13px !important;
      padding: 15px 20px;
      box-shadow: none;
      background-color: rgb(230, 230, 230);
      border-width: 0px;
      border-radius: 0px;

      &:focus {
        border-width: 0px;
        border-radius: 0px;
      }

      &::placeholder {
        color: rgba(0, 0, 0, 0.75);
      }
    }

    input[type='button'],
    input[type='submit'],
    .ui.button {
      width: auto;
      font-size: 13px !important;
      padding: 14px 20px;
      font-weight: normal;
      text-transform: uppercase;
      color: rgb(43, 43, 43);
      background-color: transparent;
      border: 2px solid rgb(43, 43, 43);
      border-image: initial;
      border-radius: 0px;
      transition: all 0.1s linear 0s;

      @media screen and (max-width: ${breakpoints.mobileMax}) {
        width: 280px;
      }

      &:hover {
        color: rgb(255, 255, 255);
        background-color: rgb(43, 43, 43);
        border-color: rgb(43, 43, 43);
      }
    }
  }
`;

const Subscribe = () => {
  const {
    setName,
    setEmail,
    error,
    success,
    performSubscribe,
    subscribing,
  } = useSubscribe({campaign: 'Subscribe Section'});

  const handleSubmit = e => {
    e.preventDefault();
    performSubscribe();
  };
  const handleNameChange = e => setName(e.currentTarget.value);
  const handleEmailChange = e => setEmail(e.currentTarget.value);

  const disabled = !!success;
  return (
    <Root className="huge-vp huge-bm baseline-hp text-centered">
      <h3 className="heading">Stay Up To Date</h3>
      <p className="subheading">
        My goal is to create helpful content and help you stay up to the date
        with the latest scoop on lifestyle and real estate in Austin and
        surrounding areas. My newsletter&apos;s sent one or twice a month, no
        spam and you can unsubscribe at any time.
      </p>

      {error && <Message compact negative content={error} />}
      {success && <Message compact success content={success} />}
      <Form disabled={disabled} onSubmit={handleSubmit}>
        <Form.Group inline>
          <Form.Input
            type="text"
            placeholder="Your name"
            aria-label="Your name"
            name="name"
            disabled={disabled}
            onChange={handleNameChange}
          />
          <Form.Input
            type="email"
            placeholder="Your email address"
            aria-label="Your email address"
            name="email"
            disabled={disabled}
            onChange={handleEmailChange}
          />
          <Form.Button
            disabled={disabled}
            loading={subscribing}
            aria-label="Sign Up"
            type="submit"
            size="big">
            SIGN UP
          </Form.Button>
        </Form.Group>
      </Form>
    </Root>
  );
};

export default Subscribe;
