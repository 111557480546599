/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import {Grid, Header, Icon, Image} from 'semantic-ui-react';
import {OutboundLink} from 'gatsby-plugin-google-analytics';
import {Link} from 'gatsby';
import Subscribe from '../Subscribe';
import useSiteSettings from '../../../hooks/use-site-settings';
import {breakpoints, colors} from '../../../theme';
import logo from '../../../images/logo.png';
import brokerLogo from '../../../images/broker-logo.png';

const FooterContainer = styled(Grid)`
  font-size: 0.8rem;

  a {
    color: ${colors.darkTextColor};
    display: inline-block;
    width: 100%;
    padding: 0.5rem 0.25rem;

    &:hover {
      color: ${colors.brandPrimary};
    }

    @media only screen and (max-width: ${breakpoints.smallScreenMax}) {
      padding: 0.625rem 0.25rem;
    }
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const CopyrightContainer = styled(Grid.Column)`
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

const SiteLink = ({to, children}) => (
  <div>
    <Link to={to}>{children}</Link>
  </div>
);

const SocialLink = ({name, to, children}) => (
  <div>
    <OutboundLink href={to} target="_blank" rel="nofollow noopener noreferrer">
      <Icon name={name} size="large" aria-label={`${name} page`} />
      {children}
    </OutboundLink>
  </div>
);

const LayoutFooter = () => {
  const siteSettings = useSiteSettings();
  return (
    <footer className="huge-tp">
      <Subscribe />

      <FooterContainer container className="small">
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <LogoContainer>
            <Image
              className="large-rm"
              size="tiny"
              src={logo}
              alt={siteSettings.siteTitle}
            />
            <Icon name="plus" size="big" />
            <Image
              size="small"
              src={brokerLogo}
              alt={siteSettings.brokerName}
            />
          </LogoContainer>
          <p className="large-hp">
            My mission is to help you realize the best outcome in your real
            estate journey. Where we live and do life is a very important
            decision. Hope this compilation of resources serves you well.
          </p>
          <p className="large-hp">
            All this would not be possible without the encouragement and
            guidance of my broker and my wonderful colleagues at{' '}
            <b>{siteSettings.brokerName}</b>
          </p>
        </Grid.Column>

        <Grid.Column mobile={8} tablet={4} computer={2}>
          <Header as="h4">Discover</Header>
          <SiteLink to="/">Home</SiteLink>
          <SiteLink to="/blog">Blog</SiteLink>
          <SiteLink to="/about">About</SiteLink>
          <SiteLink to="/contact">Contact</SiteLink>
        </Grid.Column>

        <Grid.Column mobile={8} tablet={4} computer={2}>
          <Header as="h4">Notices</Header>
          <div>Terms of Service</div>
          <div>Privacy Policy</div>
          <div>TREC Consumer Protection</div>
          <div>DMCA Notice</div>
          <div>Disclaimer</div>
        </Grid.Column>

        <Grid.Column mobile={8} tablet={4} computer={2}>
          <Header as="h4">Say Hi</Header>
          <OutboundLink
            href={siteSettings.officeAddressMapLink}
            target="_blank"
            rel="nofollow noopener noreferrer">
            <div
              style={{lineHeight: '1.25rem'}}
              dangerouslySetInnerHTML={{
                __html: siteSettings.officeAddressHtml,
              }}
            />
          </OutboundLink>
          <OutboundLink
            href={siteSettings.phoneLink}
            target="_blank"
            rel="nofollow noopener noreferrer">
            M: {siteSettings.phone}
          </OutboundLink>
        </Grid.Column>

        <Grid.Column mobile={8} tablet={4} computer={2}>
          <Header as="h4">Social</Header>
          <SocialLink to={siteSettings.facebookLink} name="facebook">
            Facebook
          </SocialLink>
          <SocialLink to={siteSettings.instagramLink} name="instagram">
            Instagram
          </SocialLink>
          <SocialLink to={siteSettings.linkedInLink} name="linkedin">
            LinkedIn
          </SocialLink>
          <SocialLink to={siteSettings.youtubeLink} name="youtube">
            YouTube
          </SocialLink>
        </Grid.Column>

        <CopyrightContainer width={16}>
          Copyright &copy; {new Date().getFullYear()}{' '}
          <b>Austin Home Properties</b>. All Rights Reserved.
        </CopyrightContainer>
      </FooterContainer>
    </footer>
  );
};

export default LayoutFooter;
