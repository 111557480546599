import React from 'react';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import {useStaticQuery, graphql} from 'gatsby';
import PageWidgetContainer from '../PageWidgetContainer';

const useData = () =>
  useStaticQuery(
    graphql`
      query {
        file(relativePath: {eq: "about-angie-ufomata-realtor.jpg"}) {
          childImageSharp {
            fluid(maxHeight: 120) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

const ImageBox = styled.div`
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 60px;
  display: inline-block;
`;

const AboutPageWidget = () => {
  const {file} = useData();
  return (
    <PageWidgetContainer title="about">
      <div className="baseline-lp baseline-rp text-centered">
        <ImageBox>
          <GatsbyImage
            fluid={file.childImageSharp.fluid}
            alt="Angie Ufomata - Realtor in Austin and Surrounding areas"
          />
        </ImageBox>
        <p className="large-tm">
          Hi, I&apos;m Angie, a Realtor&reg; in Austin and surrounding areas.
          Whether you&apos;re planted in Austin or relocating, my goal is to
          help you realize the most about the austin landscape and assist with
          any home buying or selling needs you may have.
        </p>
      </div>
    </PageWidgetContainer>
  );
};

export default AboutPageWidget;
