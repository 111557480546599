import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import PageWidgetDivider from '../../shared/PageWidgetDivider';
import AboutPageWidget from '../../shared/PageWidgets/AboutPageWidget';
import SocialPageWidget from '../../shared/PageWidgets/SocialPageWidget';
import ArticleCategoriesPageWidget from '../../shared/PageWidgets/ArticleCategoriesPageWidget';
import ArticleTagsPageWidget from '../../shared/PageWidgets/ArticleTagsPageWidget';

const Root = styled.aside`
  padding: 40px;
  background-color: #f2f2f2; //@brandHighlight

  .about-author-container {
    text-align: center;

    .author-avatar {
      display: flex;
      justify-content: center;
    }

    .author-info {
      padding: 20px 30px 10px;
    }
  }

  .follow-me-container {
    text-align: center;
  }
`;

const getSidebarWidgets = type => {
  switch (type) {
    case 'blog': // fall through
    case 'articles':
      return [
        AboutPageWidget,
        SocialPageWidget,
        ArticleCategoriesPageWidget,
        ArticleTagsPageWidget,
      ];
    default:
      return [AboutPageWidget, SocialPageWidget];
  }
};

const Sidebar = ({type, ...rest}) => {
  const [widgets, setWidgets] = useState(null);
  useEffect(() => {
    setWidgets(getSidebarWidgets(type));
  }, [type]);

  if (!widgets) return null;

  return (
    <Root>
      {widgets.map((Widget, i) => (
        <React.Fragment key={i}>
          {i === 0 ? null : <PageWidgetDivider />}
          <Widget {...rest} />
        </React.Fragment>
      ))}
    </Root>
  );
};

export default Sidebar;
