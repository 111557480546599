import React from 'react';
import styled from 'styled-components';
import PageWidgetTitle from './PageWidgetTitle';

const Root = styled.section`
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 22px;

  &::after,
  &::before {
    content: '';
    display: table;
    clear: both;
  }

  select {
    max-width: 100%;
  }
`;

const PageWidgetContainer = ({title, children}) => (
  <Root>
    <PageWidgetTitle>{title}</PageWidgetTitle>
    {children}
  </Root>
);

export default PageWidgetContainer;
