import urlJoin from 'url-join';

const replaceDelimiter = (subject, delimiter, replacement) =>
  subject.split(delimiter).join(replacement);

export default class SiteSettings {
  constructor(configuredSettings = {}) {
    const defaultSettings = {
      siteTitle: 'Austin Home Properties',
      brokerName: 'Nora Wall & Company',
      email: 'angie@austinhomeproperties.com',
      phone: '(512) 270-0966',
      facebookId: 'angie.u.realtor',
      linkedInId: 'angie-ufomata-64a11522',
      instagramId: 'angie.u.realtor',
      youtubeId: 'UCcXBrrrDY6rJyMUCO1fG53g',
      officeAddress: '3000 Polar Ln, Suite 401\nCedar Park, TX 78613',
      siteDescription:
        'Resources for all things Austin, Round Rock, Cedar Park, Texas',
      googleAnalyticsId: '',
    };

    Object.assign(this, {...defaultSettings, ...configuredSettings});

    // normalize props
    if (this?.seoImage && !this.seoImage.alt) {
      this.seoImage.alt =
        'Articles and resources by Angie Ufomata, realtor in Austin, Cedar Park, Leander, Round Rock, Pflugerville, Manor, Dripping Springs Texas';
    }
  }

  get facebookLink() {
    return `https://www.facebook.com/${this.facebookId}`;
  }

  get instagramLink() {
    return `https://www.instagram.com/${this.instagramId}`;
  }

  get linkedInLink() {
    return `https://www.linkedin.com/in/${this.linkedInId}`;
  }

  get youtubeLink() {
    return `https://www.youtube.com/channel/${this.youtubeId}`;
  }

  get emailLink() {
    return `mailto:${this.email}`;
  }

  get phoneLink() {
    return `tel:${this.phone}`;
  }

  get officeAddressMapLink() {
    return `https://maps.google.com/?q=${replaceDelimiter(
      this.officeAddress,
      '\n',
      ', ',
    )}`;
  }

  get officeAddressHtml() {
    return replaceDelimiter(this.officeAddress, '\n', '<br />');
  }

  get seoTitle() {
    return this.siteTitle || this.title;
  }

  get schemaOrgAuthor() {
    return {
      '@type': 'Person',
      name: this.author,
    };
  }

  get schemaOrgOrganization() {
    const {name, siteUrl} = this;
    return {
      '@type': 'Organization',
      name,
      url: siteUrl,
      logo: this.schemaOrgLogo,
    };
  }

  get schemaOrgDefaultImage() {
    const {siteUrl, seoImage} = this;
    return {
      '@type': 'ImageObject',
      url: urlJoin(siteUrl, seoImage.src),
      width: seoImage.width,
      height: seoImage.height,
    };
  }

  get schemaOrgLogo() {
    const {siteUrl, seoLogo} = this;
    return {
      '@type': 'ImageObject',
      url: urlJoin(siteUrl, seoLogo.src),
      width: seoLogo.width,
      height: seoLogo.height,
    };
  }
}
