import React from 'react';
import {Link} from 'gatsby';
import {Container, Grid, Header, Image, Search} from 'semantic-ui-react';
import SocialMenu from '../elements/SocialMenu';
import logo from '../../../images/logo.png';

const HeaderTopContent = ({hero}) => {
  if (hero) {
    return hero;
  }

  return (
    <Container className="page-header">
      <Grid className="site-branding">
        <Grid.Column mobile={16} tablet={16} computer={4}>
          <SocialMenu />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <Header as="h1" className="site-title">
            <Link to="/" exact="true">
              <Image src={logo} centered alt="Austin Home Properties" />
            </Link>
            <Header.Subheader>
              - Your Guide To Living In Austin Texas -
            </Header.Subheader>
          </Header>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={4}>
          <Search className="site-search" aria-label="Search Site" />
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default HeaderTopContent;
