import {trackCustomEvent} from 'gatsby-plugin-google-analytics';

const getErrorMessage = error => {
  let msg;
  if (error instanceof Error) {
    msg = error.message;
  } else if (error?.message) {
    msg = error.message;
  } else {
    msg = error;
  }

  return msg;
};

export const trackWatchVideo = ({pageLink}) => {
  trackCustomEvent({
    category: 'Watch Video',
    action: 'click',
    label: pageLink,
  });
};

export const trackWebContact = ({campaign, error}) => {
  if (error) {
    trackCustomEvent({
      category: 'Web Contact Error',
      action: 'click',
      label: `${campaign} - ${getErrorMessage(error)}`,
    });
  } else {
    trackCustomEvent({
      category: 'Web Contact',
      action: 'click',
      label: campaign,
    });
  }
};

export const trackSubscribeToNewsletter = ({campaign, error}) => {
  if (error) {
    trackCustomEvent({
      category: 'Subscribe To Newsletter Error',
      action: 'click',
      label: `${campaign} - ${getErrorMessage(error)}`,
    });
  } else {
    trackCustomEvent({
      category: 'Subscribe To Newsletter',
      action: 'click',
      label: campaign,
    });
  }
};
